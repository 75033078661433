import { ConfigProvider } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useService } from 'src/packages/di';
import { DashboardPage } from 'src/pages/dashboard/DashboardPage';
import { SettingsPage } from 'src/pages/settings/SettingsPage';
import { TabPage } from 'src/pages/tab/TabPage';

import type { FC } from 'react';

import { getLocale } from './utils';

export const Router: FC = observer(function Router() {
  const theme = useService('theme');
  const language = useService('language');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme.theme);
  }, [theme.theme]);

  return (
    <ConfigProvider
      config={{
        theme: theme.theme,
        style: 'bordered',
        localization: getLocale(language.language),
        size: 's',
      }}
      externalElement={document.body}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/tab/:id" element={<TabPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
});

export default Router;
