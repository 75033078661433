import { WellDetailsWidgetState } from '@go-widgets/well-details-widget';
import { action, comparer, makeObservable, observable, reaction } from 'mobx';

import { dashboardServiceAgent } from 'src/api/dashboard-service-agent';
import { goStorageAgent } from 'src/api/goStorageAgent';
import { wellDirectoryServiceAgent } from 'src/api/well-directory-service-agent';
import { requireService, requireServiceAccessor } from 'src/packages/di';
import { IS_PORTABLE_DEVICE } from 'src/packages/shared/consts/isPortableDevice';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';

import type { WellDetailsWidgetEntity } from './WellDetailsWidget.entity';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TGroup } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';
import { getCorrectLocale } from '../well-logs-widget/WellLogsWidget.utils';

const agents = {
  agent: dashboardServiceAgent,
  goStorageAgent: goStorageAgent,
  wellDirectoryAgent: wellDirectoryServiceAgent,
};

export class WellDetailsWidgetStore {
  private readonly groupSelectStore: GroupSelectStore;

  @observable entity: WellDetailsWidgetEntity;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();

  constructor(
    entity: WellDetailsWidgetEntity,
    groupSelectStore: GroupSelectStore,
    private readonly getAuthService = requireServiceAccessor('authService'),
    private readonly theme = requireService('theme'),
    private readonly langague = requireService('language')
  ) {
    this.entity = entity;
    this.groupSelectStore = groupSelectStore;

    makeObservable(this);
  }

  @action.bound
  setGroup(group: TGroup | null): void {
    this.entity.setGroup(group?.id ?? null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.entity.setWellId(well);
  }

  @action.bound
  exitFullScreen() {
    this.entity.setFullscreen(false);
  }

  private getNewState(wellId: number): WellDetailsWidgetState {
    const name = this.getAuthService().userInfo.name || this.getAuthService().userInfo.preferred_username;
    return new WellDetailsWidgetState(
      wellId,
      agents,
      { ...this.getAuthService().userInfo, name },
      {
        section: this.entity.section,
        theme: this.theme.theme,
        locale: getCorrectLocale(this.langague.language),
        fullscreen: this.entity.isFullscreen,
        editMode: this.entity.isEditMode,
      }
    );
  }

  @action.bound
  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.entity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  @action.bound
  enableEditMode(): void {
    if (IS_PORTABLE_DEVICE) return;

    this.entity.setFullscreen(true);
    this.entity.state?.updateParams({ editMode: true });
  }

  effect = () => {
    const disposeGroupId = reaction(
      () => this.entity.groupId,
      (groupId) => {
        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.entity);
      }
    );

    const dispose = reaction(
      () => this.groupSelectStore.groupManager.getWidgetsByGroupId(this.entity.groupId).slice(),
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.entity.wellId,
      (wellId, prevWellId) => {
        const isStateNotFound = wellId && !this.entity.state;
        const isWellIdChanged = wellId && this.entity.state && typeof prevWellId !== 'undefined';

        if (isStateNotFound) {
          const newState = this.getNewState(wellId);
          this.entity.setState(newState);
        }
        if (isWellIdChanged) {
          this.entity.state?.updateWellId(wellId);
        }
      },
      { fireImmediately: true }
    );

    const disposeStateParams = reaction(
      () => ({ theme: this.theme.theme, language: this.langague.language }),
      ({ theme, language }) => {
        this.entity.state?.updateParams({
          theme,
          locale: getCorrectLocale(language),
        });
      },
      {
        fireImmediately: true,
      }
    );

    const disposeSection = reaction(
      () => this.entity.state?.router?.section,
      (section) => {
        if (section) {
          this.entity.setSection(section);
        }
      }
    );

    return () => {
      dispose();
      disposeSection();
      disposeGroupId();
      disposeWellId();
      disposeStateParams();
    };
  };
}
