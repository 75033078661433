import { DarhboardLight as DashboardIcon, BookFill as LibrariesIcon } from '@profgeosoft-ui/icons';
import { LIBRARIES_URL } from 'src/api/consts';

export const navProps = [
  {
    name: 'Nav.dashboard',
    to: '/',
    renderIcon: (className: string) => <DashboardIcon className={className} />,
  },
  {
    name: 'Nav.libraries',
    to: LIBRARIES_URL,
    isExternalLink: true,
    renderIcon: (className: string) => <LibrariesIcon className={className} />,
  },
];
